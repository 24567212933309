import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header class="Header">
    <div class="Container">
      <div class="pt35 ph35 ph4-ns">
        <div class="Header_Inner">
          <h1 class="Header_Logo">
            <Link to={`/`}>Polydara</Link>
          </h1>
          <nav class="Header_Nav">
            <Link to={`/contact`}>Contact</Link>
          </nav>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
